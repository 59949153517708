import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import store from "./store"
import router from './router'
import mitt from 'mitt'
import VueMatomo from 'vue-matomo'

import { quillOptions as globalOptions  } from '@/tools.js'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'

const emitter = mitt()
const app     = createApp(App)

axios.defaults.headers.common['API-KEY'] = process.env.VUE_APP_API_KEY

app.config.globalProperties.axios   = axios
app.config.globalProperties.emitter = emitter

app.use(store)
app.use(router)

// https://github.com/AmazingDreams/vue-matomo
app.use(VueMatomo , {
  host: 'https://piwik.e-mobilbw.de',
  siteId: 13,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  trackInitialView: true,
  requireConsent: true,
  requireCookieConsent: true,
  enableHeartBeatTimer: true,
  debug: false,
})

QuillEditor.props.globalOptions.default = () => globalOptions
app.component('QuillEditor', QuillEditor)

app.mount('#app')
