<template>
  <router-view
      v-slot="{ Component }">
    <transition
        name="slideDown">
      <component
          :is="Component" />
    </transition>
  </router-view>
</template>
