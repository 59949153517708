<template>
  <div class="mask">
    <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid">
      <circle
          cx="50"
          cy="50"
          r="32">
        <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.25s"
            keyTimes="0; 1"
            values="0 50 50;360 50 50" />
      </circle>
    </svg>
    Daten werden geladen.
  </div>
</template>


<style lang="scss" scoped>
.mask {
  background: rgba($color: #0d1014, $alpha: 0.85);
  position: fixed;
  width: 92%;
  height: 90%;
  top: 5%;
  left: 4%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 50px rgba($color: #0d1014, $alpha: 1);
  border-radius: 50px;
  color: #fff;
}

svg {
  width: 128px;
  height: 128px;
  pointer-events: none;
  circle {
    fill: none;
    stroke: #fff;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-dasharray: 50 40;
  }
}
</style>
