<template>
  <div
      ref="root"
      class="root">
    <RouterView />
    <StrategyMatrix />
    <transition name="fade">
      <Spinner v-if="loading" />
    </transition>
    <footer
        class="footer-matrix"
        v-if="currentRoute !== 'Home' && currentRoute !== 'Login' && currentRoute !== 'Admin'"
        style="z-index: 100;">
      <button
          class="btn btn-primary btn-matrix"
          @click="openMatrix()">Strategiebaukasten</button>
      <button
          class="btn btn-primary btn-wishlist"
          :class="{ disabled: store.state.wischlist.length === 0 }"
          @click="openWishlist()">
            Ihre Merkliste
            <span
                v-if="store.state.wischlist.length !== 0"
                class="badge">{{ store.state.wischlist.length }}</span>
      </button>
    </footer>
    <div class="portrait-note">
      Die App erfordert den Landscape-Modus.
    </div>
  </div>
</template>


<script>
import { getCurrentInstance, onMounted, ref, computed } from 'vue'
import { appHeight, toggleFullscreen } from '@/tools.js'
import { useRoute } from 'vue-router'
import RouterView from '@/views/RouterView.vue'
import Spinner from '@/components/Spinner'
import StrategyMatrix from '@/components/StrategyMatrix'

import * as klaro from 'klaro'
import { config as klaroConfig } from '@/klaro.js'
import store from '@/store'

const btnSoundFile = require('@/assets/sounds/btn.mp3')
const btnSound = new Audio(btnSoundFile)
window.addEventListener('resize', appHeight)
appHeight()

export default {
  name: 'app',

  components: {
    RouterView,
    Spinner,
    StrategyMatrix
  },

  setup: () => {
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter
    const root    = ref(null)
    const loading = ref(false)
    const route   = useRoute()

    const playBtnSound = () => {
      btnSound.currentTime = 0
      btnSound.volume = 0.1
      const promise = btnSound.play()
      if (promise !== undefined) promise.catch(() => {})
    }

    const fullscreen = () => {
      playBtnSound()
      toggleFullscreen(root.value)
    }

    addEventListener('cookie-matomo-accept', () => {
      window._paq.push(['setConsentGiven'])
      window._paq.push(['setCookieConsentGiven'])
      //window._paq.push(['rememberConsentGiven'])
      //window._paq.push(['rememberCookieConsentGiven'])
    })

    addEventListener('cookie-matomo-decline', () => {
      window._paq.push(['forgetConsentGiven'])
      window._paq.push(['forgetCookieConsentGiven'])
    })

    const openMatrix = () => {
      playBtnSound()
      store.state.showMatrix = true
      emitter.emit('close-submatrix')
      store.state.showWishlist = false
    }

    const openWishlist = () => {
      playBtnSound()
      store.state.showWishlist = true
    }

    const currentRoute = computed(() => route.name)

    onMounted(() => {
      emitter.on('toggle-fullscreen', () => fullscreen())
      emitter.on('play-btn-sound',    () => playBtnSound())
      emitter.on('loading-start',     () => loading.value = true)
      emitter.on('loading-stop',      () => loading.value = false)
      klaro.setup(klaroConfig)
    })

    return {
      root,
      fullscreen,
      loading,
      store,
      currentRoute,
      openMatrix,
      openWishlist
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/colors';
@import '~@/assets/scss/fonts';

$animationDuration: 1s; /* Default value: 1s */
@import 'vue2-animate/src/sass/vue2-animate';

:root {
  --app-height: 100%;
  --mainColor: #5b6e7f;
  --mainFontColor: #fff;
  --swiper-theme-color: #5b6e7f;
  --swiper-pagination-color: #5b6e7f;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: calc(4px + 0.6vw + 0.6vh);
  font-family: 'Overpass', 'Neue Helvetica', Helvetica, Arial, sans-serif;
  touch-action: none;
  //user-select: none;
}
*:active,
*:focus {
  outline: none;
  box-shadow: none;
}

.klaro {
  line-height: 1.35em;

  .slider {
    box-shadow: none !important;
  }

  .cookie-notice {
    background: #1c1c24 !important;

    .cn-body {
      padding: 1rem 1.5rem !important;
    }
  }

  .cookie-modal {
    .cm-modal {
      background: #1c1c24 !important;

      .cm-header {
        padding: 2rem;

        .title {
          margin-bottom: 1rem;
        }
      }

      .cm-body {
        padding: 2rem;

        ul.cm-purposes {
          li.cm-purpose {
            line-height: 1.2em;
            padding-left: 80px;

            .cm-services {
              margin: 0.5rem 0;

              .cm-content {
                margin-left: -60px;

                .cm-service {
                  line-height: 1.2em;
                }
              }
            }
          }
        }
      }

      .cm-footer {
        padding: 2rem;
      }
    }
  }
}

.root {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);

  @media not all and (hover:hover) {
    height: var(--app-height);
  }
}

.mosha__toast {
  right: 20px !important;
}

.btn {
  background: #fff;
  border: none;
  font-family: 'Overpass', 'Neue Helvetica', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all ease-in-out 250ms;
  box-shadow: none;
  border: 1px solid rgba($color: #fff, $alpha: 0.25);
  border-width: 1px 1px 0 1px;

  &.btn-primary {
    background-color: var(--mainColor);
    color: var(--mainFontColor);

    &:hover {
      background-color: #333;
    }
  }
}

footer.footer-matrix {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #5b6e7f;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba($color: #fff, $alpha: 0.25);
  box-shadow: 0 0 0.5rem rgba($color: #000, $alpha: 0.25);
}

.btn-matrix,
.btn-wishlist {
  padding: 0.1rem 0;
  margin: 0 0.25rem;
  width: 12rem;
  font-size: 0.85rem;
  border-radius: 0.25rem;
  border: 1px solid rgba($color: #fff, $alpha: 0.5);
  position: relative;

  &:hover {
    background-color: #333 !important;
  }
}

.btn-wishlist {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.badge {
  position: absolute;
  background-color: #e55656;
  color: #fff;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  width: 1.1rem;
  height: 1.1rem;
  font-size: 0.6rem;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

/*
footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 10;
}
*/

.portrait-note {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--mainColor);
  color: var(--mainFontColor);
  font-weight: 900;
  padding: 10%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  display: none;
}
@media screen and (orientation: portrait) {
  .portrait-note {
    display: flex;
  }
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-hidden {
    opacity: 0;
  }
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
  .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }
  .swiper-pagination-bullet-active {
    transform: scale(1);
  }
  .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }
  .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }
  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }
  .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }
  .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: 0.12;
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-container-horizontal {
  > .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 4px;
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }
  &.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
}
</style>
