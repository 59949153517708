import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/start',
    redirect: `/start/1`
  },
  {
    path: '/start/:slide',
    name: 'Start',
    component: () => import('../views/Start.vue')
  },
  {
    path: '/autohaus',
    name: 'Autohaus',
    component: () => import('../views/Autohaus.vue')
  },
  {
    path: '/autohaus/sales',
    name: 'Sales',
    component: () => import('../views/Sales.vue')
  },
  {
    path: '/autohaus/aftersales',
    name: 'Aftersales',
    component: () => import('../views/Aftersales.vue')
  },
  {
    path: '/prozessschritt/:section/:pid',
    name: 'Prozessschritt',
    component: () => import('../views/Prozessschritt.vue')
  },
  {
    path: '/prozessschritt/:section/:pid/innovationsfeld/:iid',
    name: 'Innovationsfeld',
    component: () => import('../views/Innovationsfeld.vue')
  },
  {
    path: '/autohaus/innovationen',
    name: 'Innovations',
    component: () => import('../views/Innovations.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.state.isAuthenticated) next('/login')
    else next()
  } else next()
})

export default router
