<template>
  <div class="home">
    <h1>
      <small>Innovationsradar</small>
      Kfz-Gewerbe
    </h1>
    <button class="btn" @click="startApp()">
      <span v-if="!ready" v-text="`${progress.toFixed(0)} %`" />
      <span v-if="ready">App starten</span>
    </button>
    <div class="note">
      * Optimiert für Chrome, Edge, Firefox und Safari in der aktuellsten
      Version im <span @click="toggleFullscreen()" class="fs">Vollbildmodus.</span>
    </div>
    <div class="content">
      <p>Das Webtool „Innovationsradar Kfz-Gewerbe“ zeigt das Umfeld mit all seinen Akteuren, in dem Autohausunternehmen zukünftig agieren. Entlang der Autohausprozesse informiert es über innovative Technologien und Änderungstreiber.</p>
      <p>Fachkräfte des Kfz-Gewerbes erfahren, wie sich die Transformation auf die Abläufe im Autohaus auswirkt. Dazu können sie sich durch das „Ökosystem Kfz-Gewerbe“ navigieren. Zudem erhalten sie umfangreiche Informationen zu den verschiedenen Prozessschritten entlang der Customer Journey.</p>
      <p>Das Webtool der <a href="https://www.transformationswissen-bw.de" target="_blank">Landeslotsenstelle Transformationswissen BW</a> ist in Kooperation mit der Zukunftswerkstatt 4.0 entstanden. Es wird im Rahmen des Strategiedialogs Automobilwirtschaft BW durch das Ministerium für Wirtschaft, Arbeit und Tourismus Baden-Württemberg gefördert.</p>
    </div>
    <footer>
      <a
          href="https://www.ifa-info.de/zukunftswerkstatt"
          target="_blank">
        <img
            src="../assets/img/logo-zukunftswerkstatt.png"
            alt="Zukunftswerkstatt 4.0">
      </a>
      <a
          href="https://stm.baden-wuerttemberg.de/de/themen/strategiedialog-automobilwirtschaft/"
          target="_blank">
        <img
            src="../assets/img/logo-automobilwirtschaft.png"
            alt="Strategiedialog Automobilwirtschaft BW"
            class="small">
      </a>
      <a
          href="https://www.transformationswissen-bw.de"
          target="_blank">
        <img
            src="../assets/img/logo-transformationswissen.png"
            alt="Transformationswissen BW"
            class="small">
      </a>
    </footer>
    <div class="credits">
      © {{ new Date().getFullYear() }} // Design und Umsetzung von
      <a
          href="https://www.touchwert.de"
          target="_blank">
        touchwert
      </a>
    </div>
    <image-preloader
        :srcs="[
          '/static/img/assets.png',
          '/static/img/slide_01_marktentwicklung.png',
          '/static/img/slide_02_connected_car.png',
          '/static/img/slide_03_neukunden.png',
          '/static/img/slide_04_ladeinfrastruktur_tankinfrastruktur.png',
          '/static/img/slide_05_neue_antriebsvarianten.png',
          '/static/img/slide_06_fachkraefte_und_beschaeftigung.png',
          '/static/img/slide_07_zukunftswerkstatt.png',
          '/static/img/fallback_01_marktentwicklung.png',
          '/static/img/fallback_02_connected_car.png',
          '/static/img/fallback_03_neukunden.png',
          '/static/img/fallback_04_ladeinfrastruktur_tankinfrastruktur.png',
          '/static/img/fallback_05_neue_antriebsvarianten.png',
          '/static/img/zukunftswerkstatt_top.jpg',
          '/static/img/bg-home.jpg',
          '/static/img/bg-innovation.jpg',
          '/static/img/bg-werkstatt.jpg'
        ]"
        @loaded="loadedOne"
        @loaded-all="loadedAll" />
  </div>
</template>


<script>
import { getCurrentInstance, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { imagePreloader } from 'vue-image-preloader'

export default {
  name: 'Home',

  components: {
    imagePreloader
  },

  setup () {
    const instance = getCurrentInstance()
    const router   = useRouter()
    const route    = useRoute()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const progress = ref(0)
    const ready    = ref(false)

    const toggleFullscreen = () => {
      emitter.emit('play-btn-sound')
      emitter.emit('toggle-fullscreen')
    }

    const loadedOne = (e) => progress.value = e.progress
    const loadedAll = (e) => ready.value    = e

    const startApp = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Start',
        params: {
          slide: 1
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products
        }
      })
      // track event
      window._paq.push(['trackEvent', 'Startseite', 'App starten'])
    }

    return {
      toggleFullscreen,
      progress,
      ready,
      loadedOne,
      loadedAll,
      startApp
    }
  }
}
</script>


<style lang="scss" scoped>
.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: url('/static/img/bg-home.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  h1 {
    color: #fff;
    margin: 3rem 0 2rem 0;
    text-align: center;
    font-size: 3.5rem;
    line-height: 0.9em;

    small {
      display: block;
      font-size: 62.5%;
      text-transform: uppercase;
      font-weight: 200;
    }
  }

  a {
    color: #c1e2f1;

    &:hover {
      opacity: 0.5;
    }
  }

  .note {
    font-size: 0.7rem;
    margin-top: 1.25rem;
    font-weight: 300;
    color: rgba($color: #fff, $alpha: 0.75);

    .fs {
      color: #c1e2f1;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    padding: 0 4rem;
    font-weight: 400;
    line-height: 1.4em;
    margin: 4rem 0 2rem 0;
  }

  .btn {
    background: #ffb413;
    padding: 0.8rem 2rem;
    text-decoration: none;
    font-weight: 900;
    color: #222;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    width: 12rem;

    &:hover {
      opacity: 0.8;
    }
  }

  footer {
    background-color: #fff;
    width: 100%;
    margin-top: auto;
    padding: 0.9rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 2.5rem;
      margin: 0 2rem;

      &.small {
        height: 1.45rem;
        margin-top: 0.5rem;
      }
    }
  }

  .credits {
    text-align: center;
    font-size: 0.8rem;
    padding: 0.9rem 4rem 0.7rem 4rem;
    width: 100%;
    background-color: #222;
  }
}
</style>
