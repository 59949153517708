import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      isAuthenticated: false,
      showMatrix: false,
      showWishlist: false,
      wischlist: []
    }
  },
  mutations: {
    logIn (state) {
      state.isAuthenticated = true
    },
    logOut (state) {
      state.isAuthenticated = false
    }
  }
})

export default store
