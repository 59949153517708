<template>
  <transition
      enter-active-class="animated bounceInDown"
      leave-active-class="animated bounceOutDown">
    <div
        v-if="store.state.showMatrix"
        class="subnav-container"
        style="z-index: 80">
      <p class="lead">
        Es gibt vier grundsätzliche Strategien zur Ausrichtung Ihres Kfz-
        Gewerbes. Wählen Sie passende Ansätze und Maßnahmen aus, um Ihr
        Unternehmen zu stärken.
      </p>
      <button
          class="close"
          @click="close()">
        <svg viewBox="0 0 50 50">
          <polygon points="2.707,48.707 25,26.414 47.293,48.707 48.707,47.293 26.414,25 48.707,2.707 47.293,1.293 25,23.586 2.707,1.293 1.293,2.707 23.586,25 1.293,47.293"/>
        </svg>
      </button>
      <ul class="subnav">
        <li
            v-for="(item, idx) in matrix"
            :key="idx"
            :class="{ active: true }">
          <span class="id" v-text="idx + 1" />
          <span class="title">{{ item.title }}</span>
          <ul :class="`row-${idx + 1}`">
            <li
                v-for="child in item.children"
                :key="child.title"
                @click="setItem(child, item)">
              {{ child.title }}
              <small>{{ child.desc }}</small>
            </li>
          </ul>
        </li>
      </ul>
      <div class="content" />
    </div>
  </transition>

  <transition
      enter-active-class="animated bounceInDown"
      leave-active-class="animated bounceOutDown">
    <div
        v-if="activeItem"
        class="subnav-container"
        style="z-index: 90">
      <BackBtn
        @click="closeSubMatrix()"
        style="top: -10vh; z-index: 20"
        title="Zurück zur Übersicht" />
      <ul class="subnav">
        <li class="active">
          <span class="title">
            <div>
              {{ activeItem.parent }} → {{ activeItem.title }}
              <small>
                Wählen Sie passende Maßnahmen aus, diese werden automatisch auf Ihre Merkliste hinzugefügt.
              </small>
            </div>
          </span>
          <ul class="secondary">
            <li
                v-for="child in activeItem.children"
                :key="child"
                :class="{ active: store.state.wischlist.some(item => item['title'] === child && item['parentTitle'] === activeItem.title) }"
                @click="handleItem(child, activeItem)">
              {{ child }}
              <span class="badge-check">✔</span>
            </li>
          </ul>
        </li>
      </ul>
      <div class="content" />
    </div>
  </transition>

  <transition
      enter-active-class="animated bounceInDown"
      leave-active-class="animated bounceOutDown">
    <div
        v-if="store.state.showWishlist"
        class="subnav-container"
        style="z-index: 100">
      <button
          class="close"
          @click="closeWishlist()">
        <svg viewBox="0 0 50 50">
          <polygon points="2.707,48.707 25,26.414 47.293,48.707 48.707,47.293 26.414,25 48.707,2.707 47.293,1.293 25,23.586 2.707,1.293 1.293,2.707 23.586,25 1.293,47.293"/>
        </svg>
      </button>
      <ul class="subnav">
        <li class="active">
          <span class="title">
            <div>
              Ihre Merkliste ({{ store.state.wischlist.length }})
              <small>
                Hier erhalten Sie eine Übersicht der ausgewählten strategischen
                Maßnahmen. Sie können diese bei Bedarf abwählen und eine
                zusammenfassende PDF erstellen.
              </small>
            </div>
            <div class="buttons">
              <button
                  @click="clearWischlist()"
                  class="btn">
                <b>✕</b> &nbsp; Merkliste leeren
              </button>
              <button
                  @click="generatePdf()"
                  class="btn"
                  style="position: relative; padding-left: 3rem">
                <svg viewBox="0 0 14 19" style="height: 1rem; position: absolute; left: 1.5rem; top: 50%; transform: translateY(-50%);">
                  <path d="M14,6 L10,6 L10,0 L4,0 L4,6 L0,6 L7,13 L14,6 L14,6 Z M0,15 L0,17 L14,17 L14,15 L0,15 L0,15 Z" id="Shape"/>
                </svg>
                PDF downloaden
              </button>
            </div>
          </span>
          <div class="wishlist">
            <div
                v-for="(group, idx) in groupByAndSort(store.state.wischlist)"
                :key="idx">
              <h2>
                <span class="id" v-text="group.baseId" />
                {{ group.title }} ({{ matrix.find(item => item.id === group.baseId).title }})
                <small>{{ group.desc }}</small>
              </h2>
              <ul class="secondary">
                <li
                    v-for="child in group.items"
                    :key="child"
                    @click="handleItem(child, { title: group.title, desc: group.desc, parentId: group.baseId })">
                  {{ child }}
                  <span class="badge-close">✕</span>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <div class="content" />
    </div>
  </transition>
  <vue3-html2pdf
      pdf-content-width="800px"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :pdf-quality="2"
      :paginate-elements-by-height="1400"
      :manual-pagination="true"
      :html-to-pdf-options="{
        margin: [40, 0, 40, 0],
        filename: `Innovationsradar Kfz-Gewerbe.pdf`,
        image: {
          type: 'jpeg',
          quality: 0.9
        },
        html2canvas: {
          dpi: 200,
          scale: 3,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait'
        },
      }"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)">

    <template v-slot:pdf-content>
      <section class="pdf-content">
        <h1>Innovationsradar Kfz-Gewerbe</h1>
        <h2>Ihre strategischen Maßnahmen - {{ today.toLocaleDateString("de-DE") }}</h2>
        <div v-for="(group, idx) in groupByAndSort(store.state.wischlist)" :key="idx">
          <h3>{{ group.title }} ({{ matrix.find(item => item.id === group.baseId).title }})</h3>
          <p>{{ group.desc }}</p>
          <ul>
            <li v-for="child in group.items" :key="child">
              {{ child }}
            </li>
          </ul>
        </div>
        <p>
          Mehr Maßnahmen unter:
          <a href="https://innovationsradar.transformationswissen-bw.de">
            Innovationsradar Kfz-Gewerbe</a>
        </p>
        <h3>Machen Sie Ihren Kfz-Betrieb fit für die Zukunft!</h3>
        <p>
          Nutzen Sie dazu den Zukunfts-Check Kfz-Gewerbe. Im Workshop analysieren
          Sie unter Expertenanleitung die aktuelle Aufstellung Ihrer Kfz-Werkstat
           oder Ihres Autohauses. Sie untersuchen, welche Anpassungen in der
           Transformation der Automobilwirtschaft zwingend erforderlich sind und
           wie Sie Ihre Ausrichtung stärken können. Weitere Informationen online
           unter <a href="https://www.transformationswissen-bw.de/beratung/zukunfts-check-kfz-gewerbe">Zukunfts-Check Kfz-Gewerbe - Workshop für Kfz-Betriebe - Transformationswissen BW</a>
        </p>
        <h3>Kontaktieren Sie uns!</h3>
        <p>
          Telefonisch unter: +49 711 892385-0 oder per Mail an:
          <a href="mailto:tam@e-mobilbw.de">tam@e-mobilbw.de</a>
        </p>
      </section>
    </template>
  </vue3-html2pdf>
</template>


<script setup>
import { ref, getCurrentInstance } from 'vue'
import store from '../store'
import BackBtn from '@/components/BackBtn'
import Vue3Html2pdf from 'vue3-html2pdf'

const instance = getCurrentInstance()
const emitter = instance.appContext.config.globalProperties.emitter
const html2Pdf = ref(null)
const matrix = [
  {
    id: 1,
    title: 'Internes Wachstum',
    children: [
      {
        title: 'Neukundengewinnung',
        desc: 'Marketing zur Neukundengewinnung stärken',
        children: [
          'Bewerbung saisonaler und befristeter Angebote',
          'Coupons/ Gutscheine (z.B. Reifenwechsel)',
          'Intensivierung der Außenwirkung',
          'Kunden-werben-Kunden-Programme',
          'Leadankauf',
          'Öffentliche Veranstaltungen (z.B. Tag der offenen Tür)',
          'Online-Marketing (z.B. Banner-Werbung)',
          'Pflege des Google-Business-Profils',
          'Positive Kundenbewertungen forcieren',
          'Präsenz auf Werkstattportalen',
          'Preisaggressive Angebote',
          'Social-Media-Marketing',
          'Sonderkonditionen für Neukund:innen',
          'Sponsoring',
          'Suchmaschinenoptimierung (SEO)',
          'Suchmaschinenwerbung (SEA)',
          'Teilnahme an Gewerbeschauen, Messen oder sonstigen Veranstaltungen',
          'Werbegeschenke'
        ]
      },
      {
        title: 'HR-Management',
        desc: 'Quantitative und qualitative Mitarbeiterversorgung absichern',
        children: [
          '4-Tage-Woche',
          'Altersvorsorge',
          'Arbeitsplatzgarantien',
          'Arbeitszeitausgleich und klare Arbeitszeitregelung',
          'Berufsausbildung',
          'Berufsorientierungstage',
          'Betriebliche Altersvorsorge',
          'Betriebliche Kinderbetreuung',
          'Betriebliches Gesundheitsmanagement',
          'Bildungspartnerschaften',
          'Bonuszahlungen',
          'Corporate Benefits',
          'Eigenverantwortung, Freiräume und Mitgestaltungsmöglichkeit',
          'Employer Branding (intern und extern)',
          'Entwicklungsplan',
          'Entwicklungspläne für Mitarbeitende',
          'Faire Bezahlung',
          'Finanzielle Unterstützung bei Gesundheit und Fitness',
          'Flexible Arbeitszeiten',
          'Förderung des Betriebsklimas',
          'Fort- und Weiterbildungsangebote',
          'Fort- und Weiterbildungsbudgets',
          'Freie Bonustage (z.B. für Umzug, Hochzeit, Ehrenamt)',
          'Geschenke und Aufmerksamkeiten (z.B. bei Geburtstag, Geburt)',
          'Home-Office und mobiles Arbeiten',
          'Individuelle Entwicklungspläne',
          'Integrationsprogramm (Mitarbeiter:innen mit Migrationshintergrund)',
          'Job Rotation',
          'Job-Bikes',
          'Karriereevents und -messen',
          'Kommunikation von Unternehmensstrategie und Zielerreichung',
          'Kooperationen mit Schulen',
          'Kummerkasten',
          'Lebensarbeitszeitkonten',
          'Mentoring',
          'Mitarbeiterangebote (intern)',
          'Mitarbeiterevents',
          'Mitarbeitergespräche',
          'Mitarbeiterwohnungen (z.B. Azubi-WG)',
          'Mitarbeiterzufriedenheitsanalysen',
          'Mobilitätsbudgets',
          'Nachfolgeplanung und -regelung',
          'Praktika',
          'Sabbaticals',
          'Schärfung der Arbeitgebermarke',
          'Teambuildingmaßnahmen',
          'Übernahme von Studiengebühren',
          'Übernahmegarantien für Auszubildende',
          'Vereinskooperationen',
          'Verköstigungsangebote',
          'Vertrauensarbeitszeiten',
          'Vertrauensmitarbeiter:in',
          'Wertschätzung und Feedback',
          'Zeitgemäße Ausstattung der Arbeitsplätze'
        ]
      },
      {
        title: 'Kundenbindung',
        desc: 'Kundenzufriedenheit und -loyalität erhöhen',
        children: [
          'Aufmerksamkeiten (z.B. Geburtstage)',
          'Erinnerungen an HU/AU',
          'Kundennewsletter',
          'Predictive Maintenance',
          'Bonusprogramme (z.B. Coupons/Gutscheine)',
          'Finanzdienstleistungsprodukte',
          'Kundenportal',
          'Reparaturen nach Herstellervorgaben',
          'Ehrlichkeit und faire Reparaturvorschläge',
          'Kundenclubs',
          'Kundenzeitschrift',
          'Vermarktung von Versicherungen/ Garantien',
          'Einbau von mitgebrachten Teilen und Zubehör',
          'Kundenevents',
          'Persönlichkeit und Erreichbarkeit',
          'Zeitwertgerechte Serviceangebote',
          'Erinnerung Wartungsintervalle und saisonale Angebote',
          'Kundenkartensysteme'
        ]
      },
      {
        title: 'Aufbau- und Ablauforganisation',
        desc: 'Kostenstrukturen und Effizienz in den Prozessen erhöhen',
        children: [
          'Austausch in hierarchie- und abteilungsübergreifenden Teams',
          'Dokumentation von Datenquellen und -speicherorten',
          'Intelligente Werkstattauslastung',
          'Prozessdefinition',
          'Automatisierung & Vernetzung von Werkstattprozessen von Führungskräften',
          'Festlegung der Erreichbarkeit von Führungskräften',
          'Kundendatenmanagementsystem',
          'Qualitätsmanagement',
          'Automatisierung von Kundenanfragen',
          'Identifikation von Medienbrüchen (online/offline)',
          'Optimierung des Arbeitseinsatzes',
          'Regelkommunikation mit Führungskräften',
          'Definition von Unternehmensleitbild mit Vision und Mission',
          'Identifikation von redundanten analogen Prozessen',
          'Optimierung des Lagers (z.B. Material, Ersatzteile)',
          'Workflowanalyse (z.B. Laufwege, Werkstattorganisation)',
          'Digitalstrategie',
          'Integration eines kontinuierlichen Verbesserungsprozesses',
          'Organigramm (Zuständigkeiten und Verantwortlichkeiten)',
          'Zentralisierung nach Kompetenzfeldern'
        ]
      },
      {
        title: 'Standort- und Kommunikationspolitik',
        desc: 'Präsenz und Außenwahrnehmung erweitern',
        children: [
          "24/7 Schlüsselbox",
          "Angebot von Drop-off/ Pick-up-Points",
          "Chatbots",
          "CO2-Emissionsvermeidung und -reduzierung",
          "Digitale Kundenakten",
          "Elektronische Signatur zur Beauftragung",
          "E-Mailings",
          "Fahrzeugscanner und Profiltiefenmesser",
          "Influencerbeiträge und Clips",
          "Kommunikation über Messenger-Dienste",
          "Online-Check-in",
          "Online-Reparaturkostenkalkulator",
          "Online-Status-Updates",
          "Online-Terminvereinbarung",
          "Parkplatzangebot",
          "Partnerschaften mit lokalen Unternehmen",
          "Remote-Dialogannahme",
          "Selfservice-Bezahlsysteme",
          "SMS-Kommunikation",
          "Social-Media-Marketing",
          "Videoberatung",
          "Videoclips aus der Werkstatt",
          "Videodokumentation durchgeführter Werkstattarbeiten"
        ]
      },
      {
        title: 'Leistungsportfolio',
        desc: 'Produkt- und Dienstleistungsangebot ausdehnen',
        children: [
          'Abgasuntersuchungen',
          'Batteriecheck und -zustandsbewertung',
          'Beratung zu Fördergeldern/ THG-Quote',
          'Beratung zu Stromtarifen',
          'Betreuung neuer Marken (z.B. aus China)',
          'Desinfektion und Refreshment',
          'Dialogannahme',
          'Do-it-yourself-Arbeitsplätze',
          'Eigengarantien',
          'Fahrzeugankauf und -vermittlung',
          'Fahrzeugaufbereitung (innen/außen)',
          'Fahrzeugbeschriftung/-folierung',
          'Fahrzeuggutachten und -bewertung',
          'Fahrzeugtransport (Unfallbergung)',
          'Finanzierungsangebote für Werkstattdienstleistungen',
          'Fixpreisangebote',
          'Flatrates',
          'Flotten-/Fuhrparkmanagement',
          'Frühjahrs- und Wintercheck',
          'Gebrauchtwagenankauf und -vermittlung',
          'Glasreparatur',
          'Gutachten für Traktionsbatterien (SoH) bei gebrauchten E-Fahrzeugen',
          'Hol- und Bringservice',
          'Installationsservice für Ladelösungen',
          'Karosserie- und Lackarbeiten',
          'Klimaanlagenservice',
          'Ladeinfrastruktur/ Ladeparks',
          'Mobile Serviceangebote',
          'Mobilitätsberatung',
          'Not- und Pannendienst',
          'Nutz- und Sonderfahrzeuge',
          'Offline-Shop für Teile und Zubehör',
          'Online-Shop für Teile und Zubehör',
          'OTA-Diagnose und -Updates',
          'Photovoltaikanlagen und Energiespeicher',
          'Radeinlagerung',
          'Re-Importe',
          'Remanufacturing-Angebote',
          'Smart-Repair',
          'Um- und Ausbauten',
          'Unfallinstandsetzung',
          'Vermietung von Sonderfahrzeugen und Anhängern',
          'Vermietungs- und Sharingangebote Pkw',
          'Wartung E-Scooter',
          'Wohnmobil-/ Wohnwagenvermietung',
          'Zubehöreinlagerung',
          'Zweiradservices (z.B. Wintereinlagerung)',
        ]
      },
    ]
  },
  {
    id: 2,
    title: 'Externes Wachstum',
    children: [
      {
        title: 'Aufkauf',
        desc: 'Produkt- und Dienstleistungsangebot erweitern',
        children: [
          'Autovermietungen',
          'Batterieinstandsetzungszentren',
          'Elektrikerunternehmen',
          'Energieberater:innen',
          'Fahrzeugaufbereiter',
          'Freie Händler (z.B. EU-Reimporteur)',
          'Freie Werkstätten',
          'Fuhrparkmanager und Flottenbetreiber',
          'Glasspezialisten',
          'Herstellergebundene Betriebe',
          'Karosseriespezialisten',
          'Lackspezialisten',
          'Leder-/ Stoffspezialisten',
          'Mobiler Service für Nutzfahrzeuge',
          'Mobilitätsdienstleister (z.B. E-Scooter)',
          'Photovoltaikspezialisten',
          'Räder-/ Reifenspezialisten',
          'Schreinerhandwerk',
          'Smart-Repair-Spezialisten',
          'Spezialisten für Fahrzeugveredelung',
          'Wohnmobilhersteller oder -händler',
          'Wohnwagenhersteller oder -händler',
          'Young- oder Oltimerspezialisten',
          'Zweiradwerkstätten/ -händler'
        ]
      },
      {
        title: 'Fusion',
        desc: 'Produkt- und Dienstleistungsangebot erweitern',
        children: [
          'Fahrzeugaufbereiter',
          'Freie Händler (z.B. EU-Reimporteur)',
          'Freie Werkstätten',
          'Glasspezialisten',
          'Herstellergebundene Betriebe',
          'Karosseriespezialisten',
          'Lackspezialisten',
          'Mobiler Service für Nutzfahrzeuge',
          'Räder-/ Reifenspezialisten',
          'Smart-Repair-Spezialisten',
          'Spezialisten für Fahrzeugveredelung',
          'Werkstattketten',
          'Wohnmobilhersteller oder -händler',
          'Wohnwagenhersteller oder -händler',
          'Young- oder Oldtimerspezialisten',
          'Zweiradwerkstätten/ -händler'
        ]
      },
      {
        title: 'Kooperation',
        desc: 'Dienstleistungsangebot über Partner erweitern',
        children: [
          'Batterieinstandsetzungszentren',
          'Behörden',
          'Elektrikerunternehmen',
          'Energieberater:innen',
          'Fahrzeugaufbereiter',
          'Freie Händler (z.B. EU-Reimporteur)',
          'Freie Werkstätten',
          'Zweiradwerkstätten/ -händler',
          'Fuhrparkmanager und Flottenbetreiber',
          'Glasspezialisten',
          'Herstellergebundene Betriebe',
          'Karosseriespezialisten',
          'Lackspezialisten',
          'Ladeinfrastrukturanbieter',
          'Leder-/ Stoffspezialisten',
          'Mobiler Service für Nutzfahrzeuge',
          'Mobilitätsdienstleister (z.B. E-Scooter)',
          'Photovoltaikspezialisten',
          'Räder-/ Reifenspezialisten',
          'Schreinerhandwerk',
          'Smart-Repair-Spezialisten',
          'Spezialisten für Fahrzeugveredelung',
          'Spezialisten für Sonderumbauten',
          'THG-Prämienhändler',
          'Versicherungen',
          '(Whitelabel-)Lösungsanbieter für E-Mobilität',
          'Wohnmobilhersteller oder -händler',
          'Wohnwagenhersteller oder -händler',
          'Young- oder Oldtimerspezialisten'
        ]
      },
    ]
  },
  {
    id: 3,
    title: 'Spezialisierung',
    children: [
      {
        title: 'Technologien',
        desc: 'Einzelne Technologien fokussieren',
        children: [
          'Brennstoffzellensysteme',
          'Elektrofahrzeuge',
          'Software',
          'Verbrennungsmotoren',
          'Digitale Technologien (z.B. Remote-Updates)',
          'Fahrerassistenzsysteme',
          'Traktionsbatterien',
          'Wasserstoff-Verbrennungsmotor'
        ]
      },
      {
        title: 'Produktgruppen',
        desc: 'Produkt- und Dienstleistungsangebot kürzen und fokussieren',
        children: [
          'Anhänger',
          'Behördenfahrzeuge',
          'Fabrikate',
          'Fahrräder und Lastenräder',
          'Fahrzeugkategorien (z.B. US-Cars)',
          'Fahrzeugsegmente (z.B. Luxus)',
          'Motorisierte Zweiräder',
          'Nutzfahrzeuge',
          'Oldtimer',
          'Rasenmäher',
          'Sonderfahrzeuge (z.B. Buggies, Bergbau)',
          'Sonderfahrzeuge (z.B. Lieferdienste, Kühlfahrzeuge)',
          'Sonderfahrzeuge (Rettungsdienste, Polizei, Feuerwehr)',
          'Transporter',
          'Wohnmobile/ Wohnwagen',
          'Youngtimer'
        ]
      },
      {
        title: 'Leistungsfelder',
        desc: 'Produkt- und Dienstleistungsangebot kürzen und fokussieren',
        children: [
          'Aufbereitung',
          'Getriebe- und Motorarbeiten',
          'Mobiler Service',
          'Traktionsbatteriereparatur',
          'Fahrzeugbeschriftungen/ Folierungen',
          'Glasreparaturen',
          'Räder und Reifen',
          'Tuning und Leistungssteigerung',
          'Fahrzeugveredelung',
          'Karosserie- und Lackarbeiten',
          'Smart Repair',
          'Unfallinstandsetzung'
        ]
      },
    ]
  },
  {
    id: 4,
    title: 'Marktaustritt',
    children: [
      {
        title: 'Exit',
        desc: 'Kontrolliert zurückziehen',
        children: [
          'Verpachtung (gleiche Branche)',
          'Verpachtung (fremde Branche)',
          'Veräußerung (gleiche Branche)',
          'Veräußerung (fremde Branche)'
        ]
      }
    ]
  }
]

const activeItem = ref(null)

const setItem = (item, parent) => {
  emitter.emit('play-btn-sound')
  activeItem.value = item
  activeItem.value.parentId = parent.id
  activeItem.value.parent = parent.title
}

const handleItem = (item, parentItem) => {
  console.log(parentItem)
  emitter.emit('play-btn-sound')
  const object = {
    title: item,
    parentTitle: parentItem.title,
    parentDesc: parentItem.desc,
    baseId: parentItem.parentId
  }
  if (!store.state.wischlist.some(wishlistItem => JSON.stringify(wishlistItem) == JSON.stringify(object)))
    store.state.wischlist.push(object)
  else store.state.wischlist = store.state.wischlist.filter(wishlistItem => wishlistItem.title !== item || wishlistItem.parentTitle !== parentItem.title)
}

const close = () => {
  emitter.emit('play-btn-sound')
  store.state.showMatrix = false
}

const groupByAndSort = (array) => {
  const groupedResult = array.sort((a, b) => {
    if (a.baseId === b.baseId) {
        // Alphabetische Sortierung nach "title"
        return a.title.localeCompare(b.title)
    } else {
        // Numerische Sortierung nach "baseId"
        return a.baseId - b.baseId
    }
  }).reduce((result, currentValue) => {
    // Sucht nach der existierenden Gruppe oder erstellt eine neue
    let group = result.find(group => group.title === currentValue.parentTitle)
    if (!group) {
      group = {
        title: currentValue.parentTitle,
        desc: currentValue.parentDesc,
        baseId: currentValue.baseId,
        items: []
      }
      result.push(group)
    }
    // Füge das modifizierte Objekt zur entsprechenden Gruppe hinzu
    group.items.push(currentValue.title)

    return result
  }, [])

  return groupedResult
}

const today  = new Date()

const beforeDownload = async ({ html2pdf, options, pdfContent }) => {
  await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
    const totalPages = pdf.internal.getNumberOfPages()
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i)
      pdf.addImage('/static/img/pdf-header.png', 'PNG', 0, 0, 210, 40)
      pdf.addImage('/static/img/pdf-footer.png', 'PNG', 0, 257, 210, 40)
    }
  }).save()
}

const generatePdf = () => {
  emitter.emit('play-btn-sound')
  html2Pdf.value.generatePdf()
}

const closeSubMatrix = () => {
  emitter.emit('play-btn-sound')
  activeItem.value = null
}

const closeWishlist = () => {
  emitter.emit('play-btn-sound')
  store.state.showWishlist = false
}

const clearWischlist = () => {
  emitter.emit('play-btn-sound')
  const result = confirm("Merkliste leeren?")
  if (result) {
    store.state.wischlist = []
    store.state.showWishlist = false
  }
}

emitter.on('close-submatrix', () => closeSubMatrix())
</script>


<style lang="scss" scoped>
.subnav-container {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 12vh;
  width: 100%;
  height: calc(100% - 12vh);
  display: flex;
  flex-direction: column;

  .lead {
    position: absolute;
    right: 1.5rem;
    bottom: 6rem;
    width: 48vw;
    text-align:
    right;
    font-size: 1.5rem;
    color: #fff;
  }

  .close {
    position: absolute;
    top: -10vh;
    right: 2vh;
    width: 8vh;
    height: 8vh;
    background: #fff;
    border: none;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    svg {
      margin: 1.75vh;
      fill: #333;
      transition: all 250ms ease-in-out;
    }

    &:hover {
      background: #07223e;

      svg {
        fill: #fff;
        transform: rotate(180deg);
      }
    }
  }

  .subnav {
    display: flex;
    list-style: none;
    margin: 0;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;

    > li {
      width: 100%;
      height: 5rem;
      background: rgba($color: #5b6e7f, $alpha: 0.95);
      color: #fff;
      border-right: 1px solid rgba($color: #fff, $alpha: 0.25);
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      transition: all 500ms ease-in-out;

      &:last-child {
        border-right: none;
      }

      &.active {
        background: rgba($color: #000814, $alpha: 0.95);
        box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.2);
        z-index: 10;
      }

      span {
        text-align: center;
      }

      .id {
        position: absolute;
        font-family: 'Navada Outline', sans-serif;
        font-size: 4rem;
        height: 4rem;
        line-height: 1em;
        left: 15%;
        bottom: -0.045em;
        color: rgba($color: #fff, $alpha: 0.2);
      }

      .title {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: left;
        padding: 1rem 1rem 1rem 1.5rem;
        line-height: 1.3;
        display: flex;

        > div {
          margin-right: auto;
        }

        .buttons {
          display: flex;
          margin-right: unset;
          flex-wrap: nowrap;

          button {
            margin-left: 0.5rem;
            white-space: nowrap;
            font-size: 0.85rem;
            padding: 0.5rem 1rem;
          }
        }

        small {
          display: block;
          font-size: 0.8rem;
          color: #c1e2f1;
        }
      }

      ul {
        position: absolute;
        width: 100%;
        top: 5rem;
        list-style: none;
        padding: 0;
        margin: 0;

        &.row-1 > li {
          border-color: #aed9e0;
        }

        &.row-2 > li {
          border-color: #b8f2e6;
        }

        &.row-3 > li {
          border-color: #faf3dd;
        }

        &.row-4 > li {
          border-color: #ffa69e;
        }

        li {
          margin: 0.15rem;
          background-color: rgba($color: #07223e, $alpha: 1);
          padding: 1rem;
          cursor: pointer;
          line-height: 1.25;
          height: 5rem;
          font-weight: 700;
          border-left: 5px solid rgba($color: #fff, $alpha: 0.6);
          border-radius: 0.25rem;
          overflow: hidden;
          transition: all 250ms ease-in-out;

          small {
            display: block;
            font-size: 0.85rem;
            font-weight: 400;
            color: rgba($color: #caf0f8, $alpha: 0.9);
          }

          &:hover {
            background: rgba($color: #000814, $alpha: 0.95) !important;
          }
        }

        &.secondary {
          display: grid;
          max-height: calc(100vh - 10rem);
          grid-template-columns: repeat(5, minmax(0, 1fr));
          overflow: auto;
          padding-bottom: 4rem;

          li {
            font-size: 0.8rem;
            padding: 0.5rem 1.5rem 0.5rem 1rem;
            hyphens: auto;
            position: relative;
            color: rgba($color: #caf0f8, $alpha: 0.95);
            height: 4rem;
            font-weight: 600;

            .badge-check {
              display: none;
            }

            &.active {
              background: rgba($color: #003566, $alpha: 0.95);

              .badge-check {
                display: block;
              }
            }
          }
        }
      }

      .wishlist {
        position: absolute;
        width: 100%;
        max-height: calc(100vh - 10rem);
        top: 5rem;
        overflow: auto;
        padding-bottom: 4rem;

        > div {
          display: flex;
          margin: 1rem 0.5rem;
          padding: 0.15rem;
          background-color: rgba($color: #fff, $alpha: 0.85);
          border-bottom: 2px solid rgba($color: #000, $alpha: 0.75);
          box-shadow: 0 0 0.75rem rgba($color: #000, $alpha: 0.25);
        }

        h2 {
          background-color: rgba($color: #ffc300, $alpha: 0.95);
          //background: linear-gradient(45deg, rgba(118,200,147,1) 0%, rgba(82,182,154,1) 100%);
          padding: 0.5rem 0.75rem 0.25rem 0.75rem;
          color: #031222;
          font-size: 1rem;
          line-height: 1.1;
          width: 33.33%;
          margin: 0.15rem;
          font-weight: 800;
          position: relative;
          overflow: hidden;
          border-radius: 0.25rem;
          letter-spacing: -0.01rem;

          .id {
            color: rgba($color: #000, $alpha: 0.15);
            font-weight: 400;
            left: unset;
            right: 1.5rem;
            bottom: -0.25rem;
          }

          small {
            display: block;
            font-weight: 400;
            color: rgba($color: #07223e, $alpha: 0.65);
            text-transform: uppercase;
            font-size: 0.7rem;
            margin-top: 0.2rem;
          }
        }

        ul {
          position: relative;
          top: unset;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          padding-bottom: 0;

          li {
            padding: 0.5rem 1.5rem 0.5rem 0.75rem;
            margin: 0.15rem;
            border: none;

            .badge-close {
              visibility: hidden;
            }

            &:hover {
              .badge-close {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  .content {
    overflow: hidden;
    height: 100%;
    line-height: 1.5rem;
    animation-duration: 350ms;
    background: linear-gradient(180deg, rgba($color: #455461, $alpha: 0.95) 0%, rgba($color: #2e3c48, $alpha: 0.95));
  }
}

.badge-close {
  position: absolute !important;
  background-color: #d1495b;
  color: #001d3d;
  top: 0;
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 900;
  border-radius: 0.25rem;
}

.badge-check {
  position: absolute;
  background-color: #49d194;
  color: #001d3d;
  top: 0;
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 900;
  border-radius: 0.25rem;
}
</style>

<style lang="scss">
@import '~@/assets/scss/colors';

.pdf-content {
  width: 100%;
  background: #fff;
  padding: 20mm 30mm;
  font-size: 3mm;
  line-height: 4.25mm;

  h1 {
    margin-top: 0mm;
    font-size: 8mm;
  }

  h2 {
    margin: 4mm 0 8mm 0;
    font-size: 4mm;
  }

  h3 {
    margin: 5mm 0 0 0;
  }

  ul {
    margin: 2mm 0 5mm 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 10mm;
    line-height: 4mm;

    li {
      list-style: none;
      margin: 0;
      padding: 1mm 6mm;
      background: url('../assets/img/bullet.png') no-repeat 0 1.5mm;
      background-size: 4mm;
    }
  }
}
</style>
