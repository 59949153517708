const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}


const toggleFullscreen = (elem) => {
  if (!document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement) {
    if (elem.requestFullscreen) elem.requestFullscreen()
    else if (elem.msRequestFullscreen) elem.msRequestFullscreen()
    else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen()
    else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen(elem.ALLOW_KEYBOARD_INPUT)
  } else {
    if (document.exitFullscreen) document.exitFullscreen()
    else if (document.msExitFullscreen) document.msExitFullscreen()
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
  }
}


let counter = 0
const isTimeToUpdate = (updateRate) => counter++ % updateRate === 0


const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
const isSafari       = () => navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 ? true : false


const buildFormData = (data) => {
  /* https://stackoverflow.com/questions/16104078/appending-array-to-formdata-and-send-via-ajax */
  const formData = new FormData()
  for (let key in data) {
    Array.isArray(data[key])
      ? data[key].forEach(value => formData.append(key + '[]', value))
      : formData.append(key, data[key])
  }
  return formData
}


const quillOptions = {
  modules: {
    /* https://quilljs.com/docs/modules/toolbar/ */
    toolbar:  [
      [{ 'header': 1 }],
      [{ 'size': ['small', false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      ['blockquote', { 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image'],
      ['clean']
    ]
  },
  theme: 'bubble',
  placeholder: 'Ihr Inhalt...',
}

module.exports = {
  appHeight,
  toggleFullscreen,
  isTimeToUpdate,
  isMobileDevice,
  isSafari,
  buildFormData,
  quillOptions
}
